const apiUrl = (process.env.VUE_APP_API_URL || 'http://localhost:3001') + '/site'

function getCertificate (certificateId) {
  return get(`get-certificate/${certificateId}`)
}

function getLogo (url) {
  return getFile(url)
}

async function getFile (route) {
  const response = await fetch(route, {
    method: 'GET'
  })

  return response.blob()
}

async function get (route) {
  const headers = {
    'Content-Type': 'application/json'
  }

  const response = await fetch(`${apiUrl}/${route}`, {
    method: 'GET',
    headers
  })

  return response.json()
}

async function getLocalisation () {
  const headers = {
    'Content-Type': 'application/json'
  }

  const response = await fetch('https://ipapi.co/json/', {
    method: 'GET',
    headers
  })

  return response.json()
}

export {
  getCertificate,
  getLogo,
  getLocalisation
}
