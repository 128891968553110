<template>
  <nav id="navbar">
    <div class="navbar-logos">
    </div>
    <div class="navbar-links">
      <TheLocaleSwitcher class="locale-switcher" />
    </div>
  </nav>
</template>

<script>
import TheLocaleSwitcher from '@/components/TheLocaleSwitcher'

export default {
  components: {
    TheLocaleSwitcher
  }
}
</script>

<style scoped lang="scss">
#navbar {
  top: 0;
  z-index: 1000;
  position: fixed;
  width: 96%;
  padding: 10px 2%;
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
}

.logo {
  padding: 5px;
  height: 40px;
  margin-right: 10px;
}

#french-tech-logo {
  height: 50px;
}

.navbar-links {
  display: flex;
  flex-wrap: wrap;
  color: $blue;
  font-size: 20px;
  font-weight: bold;
}

.navbar-item {
  padding: 15px;
}

.navbar-item:hover,
.LocalizedLink-exact-active {
  text-decoration: underline;
}

.demo-link {
  max-width: 205px;
  width: 300px;
  text-align: center;
  padding: 15px;
  border: 2px solid $blue;
  border-radius: 10px;
}

.locale-switcher {
  background-color: white;
  padding: 15px;
  max-width: 72px;
}

@media (max-width: 1080px) {
  #navbar {
    flex-direction: column;
    padding: 0;
    position: initial;
    width: 100%;
  }

  .navbar-logos {
    margin: 20px 10px 5px;
  }

  .navbar-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $blue;
    width: 100%;
    font-size: 30px;
    font-weight: normal;
    color: white;
  }

  .demo-link {
    border-color: white;
    margin-bottom: 10px;
  }

  .locale-switcher {
    display: none;
  }
}

@media (max-width: 500px) {
  .navbar-links {
    display: none;
  }
}
</style>
