// See : https://medium.com/i18n-and-l10n-resources-for-developers/the-ultimate-guide-to-vue-localization-with-vue-i18n-bf98b1d40c65

import { createI18n } from 'vue-i18n'
import Cookies from 'js-cookie'
import { getLocalisation } from './data/gets'

function loadLocaleMessages () {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

function getStartingLocale () {
  const localeCookie = Cookies.get('detectedLocale')

  if (!localeCookie) {
    getLocalisation().then(result => {
      if (result?.country === 'MA') {
        Cookies.set('detectedLocale', 'ma')
        return 'ma'
      }
    })
  } else if (localeCookie === 'ma') {
    return 'ma'
  }

  const navigatorLocale = navigator.language?.split('-')[0]
  const locale = navigatorLocale === 'en' ? 'en' : 'fr'

  Cookies.set('detectedLocale', locale)

  return locale
}

const i18n = createI18n({
  locale: getStartingLocale(),
  fallbackLocale: 'fr',
  messages: loadLocaleMessages()
})

export default i18n
