<template>
  <TheMobileNavbar v-if="isMobile()" />
  <TheNavbar v-else />

  <div id="router-view">
    <router-view />
  </div>

</template>

<script>
import TheNavbar from '@/components/TheNavbar'
import TheMobileNavbar from './components/TheMobileNavbar'

export default {
  components: {
    TheNavbar,
    TheMobileNavbar
  },
  watch: {
    $route: {
      handler (to) {
        if (to.params.locale) {
          this.$i18n.locale = to.params.locale
        }

        const metaTitle =
          typeof to.meta?.title === 'function'
            ? to.meta.title(to)
            : to.meta?.title
        const metaDescription =
          typeof to.meta?.description === 'function'
            ? to.meta.description(to)
            : to.meta?.description

        const title = metaTitle
          ? this.$t(metaTitle) + ' - Remixt'
          : this.$t('meta.title')
        const description = this.$t(metaDescription || 'meta.description')

        document.title = title
        document
          .querySelector('meta[name="description"]')
          .setAttribute('content', description)
      },
      immediate: true
    },
    '$i18n.locale': {
      handler (locale) {
        document.documentElement.lang = locale
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;600;700&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400&display=swap");

body {
  margin: 0;
  background-color: white;
}

#app {
  font-family: Rubik, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: $grey;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: white;
}

#router-view {
  margin: 73px auto 0 auto;
  width: 100%;
  max-width: 1400px;
  background-color: white;
}

a {
  text-decoration: none;
  color: inherit;
}

#footer {
  margin-top: auto;
}

@media (max-width: 1400px) {
  #router-view {
    width: 100%;
  }
}

@media (max-width: 960px) {
  #router-view {
    margin-top: 0;
  }
}
</style>
