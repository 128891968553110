<template>
  <select class="locale" v-model="$i18n.locale" @change.prevent="changeLocale">
    <option value="fr">🇫🇷 FR</option>
    <option value="en">🇬🇧 EN</option>
    <option value="ma">🇲🇦 MA</option>
  </select>
</template>

<script>
export default {
  methods: {
    changeLocale (e) {
      return this.$router.push({
        name: this.$route.name,
        query: this.$route.query,
        hash: this.$route.hash,
        params: { ...this.$route.params, locale: e.target.value }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.locale {
  font-size: 16px;
  border: none;
}
</style>
