<template>
  <div class="mobile-navbar">
    <div class="mobile-navbar-icons">
    </div>
    <div class="sidebar" :class="{ 'sidebar-open': isSidebarOpen }">
      <div class="sidebar-header">
        <img src="@/assets/x-mark.png" alt="cross" class="cross" @click="toggleMenu">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isSidebarOpen: false
    }
  },
  watch: {
    $route () {
      this.isSidebarOpen = false
    }
  },
  methods: {
    toggleMenu () {
      this.isSidebarOpen = !this.isSidebarOpen
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-navbar {
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  padding: 8px;

  &-icons {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
}

.burger-icon {
  max-width: 30px;
}

.logo {
  height: 40px;
}

#french-tech-logo {
  height: 40px;
}

.cross {
  max-width: 30px;
  margin: 2px 2px 0 auto;
}

.sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: white;
  color: $darker-blue;
  top: 0;
  left: -600px;
  width: 100%;
  height: 100vh;
  transition: left 0.4s ;
  z-index: 1000;

  &-open {
    left: 0;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid $light-grey;
  }

  &-links {
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
  }

  &-item {
    padding: 15px;
  }

  &-demo-link {
    background-color: $blue;
    border-bottom: 3px solid $dark-blue;
    width: 200px;
    color: white;
    text-align: center;
    padding: 12px;
    border-radius: 10px;
    margin: auto;
    margin-top: 30px;
  }
}
</style>
