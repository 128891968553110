<template>
  <router-link :to="localizedTo">
    <slot />
  </router-link>
</template>

<script>
export default {
  props: ['to'],
  computed: {
    localizedTo() {
      let localizedTo = this.to
      localizedTo['params'] = { ...localizedTo.params, locale: this.$i18n.locale }

      return localizedTo
    }
  }
}
</script>
