import { createRouter, createWebHistory } from 'vue-router'

function load (component) {
  return () => import(`@/views/${component}`)
}

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFoundPage',
    beforeEnter: (to, from) => {
      return '/'
    }
  },
  {
    path: '/:certificateId',
    name: 'CertificatePage',
    component: load('CertificatePage'),
    meta: {
      title: 'certificate.meta.title',
      description: 'certificate.meta.description'
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    if (to.hash) {
      return {
        el: to.hash,
        top: 100
      }
    }

    return {
      top: 0
    }
  }
})

export default router
