import { createApp } from 'vue'
import i18n from './i18n'
import VueApexCharts from 'vue3-apexcharts'
import App from './App.vue'
import router from './router'
import LocalizedLink from './components/LocalizedLink'

createApp(App)
  .component('LocalizedLink', LocalizedLink)
  .use(i18n)
  .use(VueApexCharts)
  .use(router)
  .mixin({
    methods: {
      isMobile () {
        return window.innerWidth < 500
      }
    }
  })
  .mount('#app')
